<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
import router from "@/router/index.js";
//import router from "@/router/index.js";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Recherche de course",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Recherche de course",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Recherche de course",
          active: true,
        },
      ],
      thenDay: "",
      id: "",
      totalCourse: 0,
      totalInitCourse: 0,
      totalRowsDisable: 0,
      currentPage: 1,
      perPage: 100,
      currentPageI: 1,
      perPageI: 100,
      pageOptions: [500, 1000, 2000],
      filter: null,
      filterOn: [],
      courseData: [],
      initCourse: [],
      alerteDataDes: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "client", label: "Client" },
        { key: "phone", sortable: true, label: "Téléphone" },
        { key: "driverAround", label: "Conducteur proches" },
        { key: "driverReached", label: "Conducteur atteint" },
        { key: "acceptedBy", label: "Accepté par" },
        { key: "lieuDepart", sortable: true, label: "Départ " },
        { key: "lieuArrive", sortable: true, label: "Arrivé " },
        { key: "distance", sortable: true, label: "Distance (Km)" },
        { key: `dateCreation`, label: "Date" },

        { key: "prixFinal", label: "Prix de la course" },
        { key: "moyenTransport", sortable: true, label: "Moyen de transport" },
      ],
      fieldsI: [
        { key: "client", label: "Client" },
        { key: "phone", sortable: true, label: "Téléphone" },
        { key: "lieuDepart", sortable: true, label: "Départ " },
        { key: "lieuArrivee", sortable: true, label: "Arrivé " },
        { key: "details.distances", sortable: true, label: "Distance (Km)" },
        { key: `createdAt`, label: "Date" },
        { key: "prix", label: "Prix Estimés" },
      ],

      startValue: 1,
      endValue: 500,
      totalValue: "0",
      userChargeLoader: false,
      newDatTrajetRechercher: [],
      newStart: false,
      showdisable: false,
      parBon: 500,
      history: {
        id: null,
        user: null,
      },
      deleteModal: false,
      foundData: [],
      foundDataI: [],
      keyWord: [],
    };
  },

  async mounted() {
    this.init();
    this.initSearch();
  },

  methods: {

    goToProfilClient(id) {

      router.push(`/user/user_info/${id}`)

    },
    async init() {
      const request = await apiRequest(
        "GET",
        "courses-historique-recherche",
        undefined,
        false
      );

      if (request && request.data) {
        console.log("les données du trajet", request.data)

        request.data.map(el => {
          this.courseData.push(el)
        })
        //this.id = recherche.id;

        this.totalCourse = this.courseData.length;
        console.log("this.courseData", this.courseData)
      }
    },
    async initSearch() {
      const request = await apiRequest(
        "GET",
        "admin/recherches-vtc",
        undefined,
        false
      );

      if (request && request.data) {
        console.log("les données du trajet", request.data)

        request.data.rechercheFound.map(el => {
          this.initCourse.push(el)
        })
        //this.id = recherche.id;

        this.totalInitCourse = this.initCourse.length;
        console.log("this.courseData initCourse", this.initCourse)
      }
    },

    async rechercheI() {

      this.keyWord = this.keyWord.toLowerCase()
      console.log("initCourse", this.initCourse);
      this.foundDataI = this.initCourse.filter(data => data.user.lastname.toLowerCase().includes(this.keyWord) ||
        data.user.firstname.toLowerCase().includes(this.keyWord) ||
        (data.user.indicatifTel + data.user.tel).includes(this.keyWord) ||
        (data.user.lastname.toLowerCase() + " " + data.user.firstname.toLowerCase()).includes(this.keyWord))


      console.log("foundI ", this.foundDataI)
    },
    async recherche() {

      this.keyWord = this.keyWord.toLowerCase()

      this.foundData = this.courseData.filter(data => data.passager.personne.lastname.toLowerCase().includes(this.keyWord) ||
        data.passager.personne.firstname.toLowerCase().includes(this.keyWord) ||
        (data.passager.personne.indicatifTel + data.passager.personne.tel).includes(this.keyWord) ||
        (data.passager.personne.lastname.toLowerCase() + " " + data.passager.personne.firstname.toLowerCase()).includes(this.keyWord))


      console.log("found ", this.foundData)
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;

      this.$v.$touch();
    },

    tooltipForm() {
      this.submitform = true;
      this.formSubmit();
      this.$v.$touch();
    },
    onFiltered(filteredItems) {
      this.totalCourse = filteredItems.length;
      this.currentPage = 1;
    },

    async deleteOneHistory() {
      const response = await apiRequest('DELETE', 'admin/delete/recherches-recherche', { id: this.history.id })
      if (response && response.status == 200) {
        this.deleteModal = false
        this.$toast.success("Opération réussie")
        await this.init()
      }


    },
    async deleteAllHistory() {
      const response = await apiRequest('DELETE', 'admin/delete/recherches-recherche', { user: this.history.user })
      if (response && response.status == 200) {
        this.deleteModal = false
        this.$toast.success("Opération réussie")
        await this.init()
      }

    },
    deleteModalOpener(history) {
      console.log("history", history)

      this.history.id = history.id
      this.history.user = history.users[0].id

      this.deleteModal = true


    },
    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    goToDetail(row) {
      console.log("row:", row);
    },
    desactivity(row) {
      this.showdisable = true;
      this.rowCall = row.id;
    },
    async desactive() {
      this.showdisable = false;
      const returnAddSucess = await apiRequest(
        "POST",
        "admin/alerte-active",
        {
          id: this.rowCall,
          active: false,
        },
        false
      );
      if (returnAddSucess && returnAddSucess.data) {
        this.init();


        this.$toast.success("Opération effectuée avec succès !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
      }
    },

    /**Paginaiton**/

    async actif(page, limit) {
      //Chargement des données
      console.log("début ", page);
      console.log("fin ", limit);
      const data = await apiRequest(
        "GET",
        "admin/recherches?page=" + page + "&limit=" + limit,
        undefined,
        false
      );
      if (data && data.data) {
        console.log(data.data)
        const alerteTrajetsTableData = data.data.rechercheFound.map((recherche) => {
          var usersData = []
          usersData.push({ id: recherche.user.id, user: recherche.user.firstname + " " + recherche.user.lastname, })

          console.log("user data", usersData)
          return {
            id: recherche.id,
            itinéraire:
              recherche.lieuDepart + " " + "↦" + " " + recherche.lieuArrivee,
            users: usersData,
            tel:
              recherche.user && recherche.user.tel != ""
                ? recherche.user.indicatifTel + recherche.user.tel
                : "",
            date: new Date(recherche.createdAt).toLocaleString("fr-FR"),
            depart: new Date(recherche.dateHeure).toLocaleDateString("fr-FR"),
          };
        });
        this.newDatTrajetRechercher = alerteTrajetsTableData;

        this.userChargeLoader = false;
        this.totalCourse = this.newDatTrajetRechercher.length;
      }
    },

    decrement() {
      this.userChargeLoader = true;

      this.startValue -= this.parBon;
      this.endValue -= this.parBon;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },

    increment() {
      this.userChargeLoader = true;
      this.startValue += this.parBon;
      this.endValue += this.parBon;

      this.actif(this.startValue, this.endValue);
      console.log("userChargeLoader", this.userChargeLoader);
    },
    incrementToLast() {
      this.userChargeLoader = true;
      this.startValue = this.totalValue - this.parBon;
      this.endValue = this.totalValue;
      console.log("this.startValue", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },
    firstVal() {
      this.userChargeLoader = true;
      this.startValue = 1;
      this.endValue = this.parBon;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },
    goToUserDetail(id) {
      //const routeData = router.resolve({name: 'infoUserPage', params:{idUser:id}});
      //router.push(`/user/user_info/${id}`);
      window.open(`/user/user_info/${id}`, '_blank', 'noreferrer');

      // window.open(routeData.href, '_blank');
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="containerDot" v-if="courseData.length == 0">
            <span class="loading">Chargement en cours... </span>
            <!--  <div class="dots">
              <div class="dot dot-1"></div>
              <div class="dot dot-2"></div>
              <div class="dot dot-2"></div>
            </div> -->
          </div>
          <div class="d-flex align-items-center otherThing" v-if="userChargeLoader === true">
            <span class="loading">Chargement en cours...</span>
            <div aria-hidden="true"></div>
          </div>
          <div class="card-body">
            <div class="row d-flex justify-content-between align-items-center p-2">
              <div>
                <!-- <h4 class="card-title mt-4">
                  Total recherches : {{ foundData.length != 0
      ? foundData.length
      : courseData.length }}
                </h4> -->
              </div>
              <div>
                <!-- <b-pagination v-model="currentPage" :total-rows="foundData.length != 0
      ? foundData.length
      : courseData.length" :per-page="perPage" :limit="2" first-text="◀" last-text="▶"
                  aria-controls="my-table">></b-pagination> -->
              </div>
            </div>
            <form>
              <b-tabs nav-class="nav-tabs-custom">

                <b-tab title-link-class="p-3">
                  <template v-slot:title>
                    <a class="font-weight-bold active">Courses recherchées non créées {{ foundDataI.length != 0
      ? foundDataI.length
      : initCourse.length }}</a>
                  </template>
                  <div class="row inputCol d-flex justify-content-between mt-4 mb-2">
                    <div class="p-3"></div>
                    <!-- Search -->
                    <div class="p-3">
                      <!-- <b-pagination v-model="currentPage" :total-rows="foundData.length != 0
                      ? foundData.length
                      : courseData.length" :per-page="perPage" :limit="2" first-text="◀" last-text="▶" aria-controls="my-table">
                      </b-pagination> -->

                      <b-pagination v-model="currentPageI" :total-rows="totalInitCourse" :per-page="perPageI" :limit="1"
                        first-text="◀" last-text="▶" aria-controls="my-table"></b-pagination>

                      <div class="input">
                        <input type="text" id="serchInput" placeholder="Rechercher..." v-model="keyWord"
                          @input="rechercheI()" />
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <div class="table-responsive">
                    <b-table id="my-table" class="table-centered"
                      :items="foundDataI.length != 0 ? foundDataI : initCourse" :fields="fieldsI" responsive="sm"
                      :per-page="perPageI" :current-page="currentPageI" :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
                      @filtered="onFiltered">

                      <template v-slot:cell(createdAt)="row">
                        {{ new Date(row.value).toLocaleDateString() + ` - ` + new
      Date(row.value).toLocaleTimeString("FR-fr") }}
                      </template>
                      <template v-slot:cell(client)="row">

                        <div @click="goToProfilClient(row.item.user.id)" class="cursor-pointer" style="cursor: pointer">
                          {{ row.item.user.lastname + " " + row.item.user.firstname }}
                        </div>

                      </template>
                      <template v-slot:cell(prix)="row">

                        <div v-for="(el, index) in row.item.details.margePrix" :key="index" class="cursor-pointer"
                          style="cursor: pointer">
                          <span> {{ (el.nbr_driver) + el.moyenTransport + " -> " + el.prix + " " }},</span>
                        </div>

                      </template>

                      <template v-slot:cell(phone)="row">
                        <a :href="'https://wa.me/' + row.item.user.indicatifTel + row.item.user.tel"
                          class="text-success" target="_blank">{{ row.item.user.indicatifTel + " " +
      row.item.user.tel }}</a>

                      </template>







                    </b-table>
                  </div>
                  <div class="row justify-content-end">
                    <b-pagination v-model="currentPageI" :total-rows="totalInitCourse" :per-page="perPageI" :limit="1"
                      first-text="◀" last-text="▶" aria-controls="my-table"></b-pagination>
                  </div>
                </b-tab>

                <b-tab title-link-class="p-3">
                  <template v-slot:title>
                    <a class="font-weight-bold active">Liste des courses recherchées {{ foundData.length != 0
      ? foundData.length
      : courseData.length }}</a>
                  </template>
                  <div class="row inputCol d-flex justify-content-between mt-4 mb-2">
                    <div class="p-3"></div>
                    <!-- Search -->
                    <div class="row d-flex p-3">
                      <!-- <b-pagination v-model="currentPage" :total-rows="foundData.length != 0
                      ? foundData.length
                      : courseData.length" :per-page="perPage" :limit="2" first-text="◀" last-text="▶" aria-controls="my-table">
                      </b-pagination> -->

                      <b-pagination v-model="currentPage" :total-rows="totalCourse" :per-page="perPage" :limit="1"
                        first-text="◀" last-text="▶" aria-controls="my-table"></b-pagination>

                      <div class="input">
                        <input type="text" id="serchInput" placeholder="Rechercher..." v-model="keyWord"
                          @input="recherche()" />
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <div class="table-responsive">
                    <b-table id="my-table" class="table-centered" :items="foundData.length != 0
      ? foundData
      : courseData
      " :fields="fields" responsive="sm" :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
                      @filtered="onFiltered">

                      <template v-slot:cell(dateCreation)="row">
                        {{ new Date(row.value).toLocaleDateString() + ` - ` + new
      Date(row.value).toLocaleTimeString("FR-fr") }}
                      </template>
                      <template v-slot:cell(client)="row">

                        <div @click="goToProfilClient(row.item.passager.id)" class="cursor-pointer"
                          style="cursor: pointer">
                          {{ row.item.passager.personne.lastname + " " + row.item.passager.personne.firstname }}
                        </div>

                      </template>
                      <template v-slot:cell(driverAround)="row">

                        <div class="cursor-pointer" style="cursor: pointer">
                          <div
                            v-if="row.item.conducteur_a_proximite != undefined && row.item.conducteur_a_proximite != null">
                            <div v-for="(item, index) in row.item.conducteur_a_proximite" :key="index">
                              {{ item.personne.firstname + " " + item.personne.lastname }} (<a
                                :href="'https://wa.me/' + item.personne.indicatifTel + item.personne.tel"
                                class="text-success" target="_blank">{{ item.personne.indicatifTel + " " +
      item.personne.tel }}</a>) <br>

                            </div>

                          </div>
                          <div v-else>
                            Aucun counducteur
                          </div>
                          <!-- {{ row.item.conducteur_a_proximite ? row.item.conducteur_a_proximite : " " }} -->
                        </div>

                      </template>
                      <template v-slot:cell(driverReached)="row">

                        <div class="cursor-pointer" style="cursor: pointer">
                          <div v-if="row.item.conducteurs_ayant_recu_demande != undefined">
                            <div v-for="(item, index) in row.item.conducteurs_ayant_recu_demande" :key="index">
                              {{ item.conducteur.personne.firstname + " " + item.conducteur.personne.lastname }}(<a
                                :href="'https://wa.me/' + item.conducteur.personne.indicatifTel + item.conducteur.personne.tel"
                                class="text-success" target="_blank">{{ item.conducteur.personne.indicatifTel + " " +
      item.conducteur.personne.tel }}</a>) <br>

                            </div>

                          </div>
                          <div v-else>
                            Aucun counducteur
                          </div>
                          <!-- {{ row.item.conducteurs_ayant_recu_demande ? row.item.conducteurs_ayant_recu_demande : " " }} -->
                        </div>

                      </template>
                      <template v-slot:cell(acceptedBy)="row">

                        <div class="cursor-pointer" style="cursor: pointer">
                          <div v-if="row.item.conducteur">

                            {{ row.item.conducteur.personne.firstname + " " + row.item.conducteur.personne.lastname }}(<a
                                :href="'https://wa.me/' + row.item.conducteur.personne.indicatifTel + row.item.conducteur.personne.tel"
                                class="text-success" target="_blank">{{ row.item.conducteur.personne.indicatifTel + " " +
                              row.item.conducteur.personne.tel }}</a>)
                          </div>
                          <div v-else>

                            Aucun

                          </div>
                          </div>
                      </template>
                      <template v-slot:cell(phone)="row">
                        <a :href="'https://wa.me/' + row.item.passager.personne.indicatifTel + row.item.passager.personne.tel"
                          class="text-success" target="_blank">{{ row.item.passager.personne.indicatifTel + " " +
      row.item.passager.personne.tel }}</a>

                      </template>






                    </b-table>
                  </div>
                  <div class="row justify-content-end">
                    <b-pagination v-model="currentPage" :total-rows="totalCourse" :per-page="perPage" :limit="1"
                      first-text="◀" last-text="▶" aria-controls="my-table"></b-pagination>
                  </div>
                </b-tab>
              </b-tabs>
            </form>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modal-backdrop" centered v-model="showdisable" title="Voulez-vous vraiment suspendre cet utilisateur ?"
      title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showdisable = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="desactive">Oui</b-button>
      </div>
    </b-modal>
    <b-modal id="modal-backdrop" centered v-model="deleteModal" title="Choisissez le type de suppression !"
      title-class="font-18" hide-footer>
      <div class="d-flex py-3 justify-content-center text-center ">
        <i class="fas fa-times text-danger fa-xl font-32  p-2 border border-danger rounded-circle"></i>
      </div>
      <div class="d-flex justify-content-between mt-3 ">

        <b-button variant="danger" @click="deleteAllHistory">Tout l'historique</b-button>
        <b-button variant="success" class=" " @click="deleteOneHistory">Historique actuel</b-button>
        <b-button variant="info" class=" mr-4" @click="deleteModal =false">Annuler</b-button>
      </div>
    </b-modal>
  </Layout>
</template>

<style>
thead {
  background: #3AAA35;
}

thead div {
  color: #fff;
}

.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 35px;
  top: -50%;

  flex: 60%;

  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}

.input input:focus {
  border: 3px solid #3aaa35;
}

.inputCol {
  position: relative;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #3AAA35;
  border-color: #fff;
}

.loading {
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
}

.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}

.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -50px;
}

.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: -13%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

ion-icon {
  cursor: pointer;
}

.myBouton {
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
}

.containerDot {
  z-index: 100;
}

.paging_simple_numbers {
  background: #3AAA35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
}

.paging_simple_numbers ul li {
  color: white !important;
}


.paging_simple_numbers ul li button {
  color: #fff !important;
}
</style>
